const config = {
    title: 'AT&T WebStore',
    client: 'attus',
    defaultLocale: 'en-US',
    supportedLanguages: ['en-US'],
    allowGuest: true,
    showScrollToTop: true,
    choose_your_plan: 'Choose your plan',
    showDiscountCode: false,
    showMoreInformationDeepLink: true,
    termsAndConditionsLink:
        'https://www.att.com/legal/terms.exhibitS.html#:~:text=Notwithstanding%20any%20remedies%20that%20may,3)%20you%20have%20performed%20any',
    authErroRedirectionUrl: '/root-discovery',
    authErrorHandlingType: 'notification', // notification, redirect and error-page
    epg: {
        numOfDaysFuture: 7,
        numOfDaysPast: 1,
    },
    bootRequests: {
        lastChannelsLcn: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        recordings: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        recordingsFavorites: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        reminders: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        continueWatching: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        channelApps: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        products: {
            requestAtBoot: true,
            waitAtBoot: true,
        },
        liveNow: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        vodNodes: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        recommendation: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        vodFavorites: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
        channels: {
            requestAtBoot: false,
            waitAtBoot: false,
        },
    },
    hasPortalScreen: true,
    contentNodePageSize: 100,
    // EMI provides layout/format info for items/rows
    supportedLayoutsFromEMi: ['vod', 'predefined', 'app'],
    // MAC-638 - default node ordering in descending order pending feature from EMI
    proxy: {
        defaultNodeOrdering: 'node_pos_desc',
        defaultCategoryOrdering: 'node_pos_desc', // Hack as feature not support by EMI and starhub very demanding
        enableFetchProductTitles: false,
    },
    // use this object to add custom User Friendly error messages
    customErrorMsg: {
        globalErrorMessages: {
            AUTHENTICATION_REQUIRED: {
                title: '',
                description: 'User session has expired. Please log in again.',
                subDescription: '',
                sticky: false,
            },
            'access-denied': {
                title: '',
                description: 'You are not allowed to do the selected operation',
                subDescription: '',
                sticky: false,
            },
        },
        addToCartFailed: {
            errorCode: {},
            title: '',
            description: 'AddToCartFailed',
            subDescription: '',
            sticky: false,
        },

        deleteFromCartFailed: {
            errorCode: {
                USER_PREFERENCE_UPDATE_FAILED: {
                    title: '',
                    description: 'UserPreferenceUpdateFailed',
                    subDescription: '',
                },
            },
            title: '',
            description: 'DeleteFromCartFailed',
            subDescription: '',
            sticky: false,
        },
        duplicateCartItem: {
            errorCode: {},
            title: '',
            description: 'DuplicateCartItem',
            subDescription: '',
            sticky: false,
        },
        offerPurchaseSuccess: {
            errorCode: {},
            title: '',
            description: 'OfferPurchaseSuccess',
            subDescription: '',
            sticky: false,
        },
        reactivateSubscriptionSuccess: {
            errorCode: {},
            title: '',
            description: 'ReactivateSubscriptionSuccess',
            subDescription: '',
            sticky: false,
        },
        reactivateSubscriptionFailed: {
            errorCode: {
                SUBSCRIPTION_REACTIVATION_FAILED: {
                    title: '',
                    description: 'ReactivateSubscriptionFailed',
                    subDescription: '',
                },
                'authentication-required': {
                    title: '',
                    description: 'AuthenticationRequired',
                    subDescription: '',
                },
            },
            title: '',
            description: 'ReactivateSubscriptionFailed',
            subDescription: '',
            sticky: false,
        },
        pauseSubscriptionSuccess: {
            errorCode: {},
            title: '',
            description: 'PauseSubscriptionSuccess',
            subDescription: '',
            sticky: false,
        },
        pauseSubscriptionFailed: {
            errorCode: {
                'bad-request': {
                    title: 'bad-request',
                    description: 'BadRequest',
                    subDescription: '',
                },
                'authentication-required': {
                    title: '',
                    description: 'AuthenticationRequired',
                    subDescription: '',
                },
                SUBSCRIPTION_PAUSE_FAILED: {
                    title: '',
                    description: 'PauseSubscriptionFailed',
                    subDescription: '',
                },
            },
            title: '',
            description: 'PauseSubscriptionFailed',
            subDescription: '',
            sticky: false,
        },
        resumeSubscriptionSuccess: {
            errorCode: {},
            title: '',
            description: 'ResumeSubscriptionSuccess',
            subDescription: '',
            sticky: false,
        },
        resumeSubscriptionFailed: {
            errorCode: {
                'authentication-required': {
                    title: '',
                    description: 'AuthenticationRequired',
                    subDescription: '',
                },
                'input-conflict': {
                    title: '',
                    description: 'ResumeSubscriptionFailed409',
                    subDescription: '',
                },
            },
            title: '',
            description: 'ResumeSubscriptionFailed',
            subDescription: '',
            sticky: false,
        },
        updateSubscriptionSuccess: {
            errorCode: {},
            title: '',
            description: 'UpdateSubscriptionSuccess',
            subDescription: '',
            sticky: false,
        },
        upgradeSubscriptionSuccess: {
            errorCode: {},
            title: '',
            description: 'UpgradeSubscriptionSuccess',
            subDescription: '',
            sticky: true,
        },
        cancelDowngradeSuccess: {
            errorCode: {},
            title: '',
            description: 'CancelDowngradeSuccess',
            subDescription: '',
            sticky: false,
        },
        updateSubscriptionFailed: {
            errorCode: {
                'invalid-request': {
                    title: '',
                    description: 'UpdateSubscriptionFailedInitialTransaction',
                    subDescription: '',
                },
                'eligibility-failed': {
                    title: '',
                    description: 'UpdateSubscriptionFailedEligibilityFailed',
                    subDescription: '',
                },
                'server-error': {
                    title: '',
                    description: 'UpdateSubscriptionFailedServerError',
                    subDescription: '',
                },
                'The billing plan or the campaign has exceed the allowed maxUsage, maxUsageActive or is out of allowed quantityRange':
                    {
                        title: '',
                        description: 'BillingMaxUsageAndMaxUsageActiveError',
                        subDescription: '',
                    },
                'Modify transaction authorization failed': {
                    title: '',
                    description: 'UpgradeSubscriptionFailedInvalidRequest',
                    subDescription: '',
                },
                'Still processing a previous transaction for this AutoBill. Please try modification again later.': {
                    title: '',
                    description: 'UpdateSubscriptionFailed',
                    subDescription: '',
                },
            },
            title: '',
            description: 'updateSubscriptionFailed',
            subDescription: '',
            sticky: false,
        },
        unsubscribeProductSuccess: {
            errorCode: {},
            title: '',
            description: 'UnsubscribeProductSuccess',
            subDescription: '',
            sticky: false,
        },
        unsubscribeProductSuccessForImmidiateCancel: {
            errorCode: {},
            title: '',
            description: 'unsubscribeProductSuccessForImmidiateCancel',
            subDescription: '',
            sticky: false,
        },
        unsubscribeProductFailed: {
            errorCode: {
                NOT_FOUND: {
                    title: '',
                    description: 'NotFound',
                    subDescription: '',
                },
                'input-conflict': {
                    title: '',
                    description: 'CancelSubscription409',
                    subDescription: '',
                },
                'authentication-required': {
                    title: '',
                    description: 'AuthenticationRequired',
                    subDescription: '',
                },
                UNSUBSCRIBE_FAILED: {
                    title: '',
                    description: 'UnsubscribeProductFailed',
                    subDescription: '',
                },
            },
            title: '',
            description: 'UnsubscribeProductFailed',
            subDescription: '',
            sticky: false,
        },
        purchaseFailed: {
            errorCode: {
                'invalid-request': {
                    title: '',
                    description: 'PurchaseFailedPaymentMethod',
                    subDescription: '',
                },
            },
            title: '',
            description: 'PurchaseFailedPaymentMethod',
            subDescription: '',
            sticky: false,
        },
        emailAuthFail: {
            errorCode: {
                EMAIL_NOT_VERIFIED: {
                    title: '',
                    description: 'EmailNotVerified',
                    subDescription: '',
                },
            },
            title: '',
            description: 'EmailAuthFail',
            subDescription: '',
            sticky: false,
        },
        otpResendSuccess: {
            errorCode: {},
            title: '',
            description: 'OtpResendSuccess',
            subDescription: '',
            sticky: false,
        },
        otpValidationFailed: {
            errorCode: {
                UPDATE_AUTH_IDENTIFIER_OTP_VALIDATION_FAILED: {
                    title: '',
                    description: 'UpdateAuthIdentifierOtpValidationFailed',
                    subDescription: '',
                },
            },
            title: '',
            description: 'OtpValidationFailed',
            subDescription: '',
            sticky: false,
        },
        otpExpired: {
            errorCode: {},
            description: 'OtpExpired',
            subDescription: '',
            sticky: false,
        },
        emailUpdateSuccess: {
            errorCode: {},
            title: '',
            description: 'EmailUpdateSuccess',
            subDescription: '',
            sticky: false,
        },
        addressAuthFail: {
            errorCode: {},
            title: '',
            description: 'AddressAuthFail',
            subDescription: '',
            sticky: false,
        },
        addressUpdateSuccess: {
            errorCode: {},
            title: '',
            description: 'AddressUpdateSuccess',
            subDescription: '',
            sticky: false,
        },
        activateSubscriptionSuccess: {
            errorCode: {},
            title: '',
            description: 'ActivateSubscriptionSuccess',
            subDescription: '',
            sticky: false,
        },
        activateSubscriptionFailed: {
            errorCode: {},
            title: '',
            description: 'ActivateSubscriptionFailed',
            subDescription: '',
            sticky: false,
        },
        addressVerificationFail: {
            errorCode: {
                ADDRESS_VERIFICATION_FAILED: {
                    title: '',
                    description: 'AddressVerificationFailed',
                    subDescription: '',
                },
                VERIFY_ACCOUNT_ADDRESS_FAILED: {
                    title: '',
                    description: 'VerifyAccountAddressFailed',
                },
                'GA-V100012': {
                    title: '',
                    description: 'AddressStateValidationFailed',
                },
                'GA-V100011': {
                    title: '',
                    description: 'AddressPincodeValidationFailed',
                },
            },
            title: '',
            description: 'AddressVerificationFail',
            subDescription: '',
            sticky: false,
        },
        addressVerificationMissingInfo: {
            title: '',
            description: 'AddressVerificationMissingInfo',
            subDescription: '',
            sticky: false,
        },
        paymentMethodUpdateSuccess: {
            errorCode: {},
            title: '',
            description: 'PaymentMethodUpdateSuccess',
            subDescription: '',
            sticky: false,
        },
        paymentMethodUpdateFailed: {
            errorCode: {},
            title: '',
            description: 'PaymentMethodUpdateFailed',
            subDescription: '',
            sticky: false,
        },

        emailUpdatedSuccessfully: {
            errorCode: {},
            title: '',
            description: 'EmailUpdatedSuccessfully',
            subDescription: '',
            sticky: false,
        },
        failToUpdateEmail: {
            errorCode: {},
            title: '',
            description: 'FailToUpdateEmail',
            subDescription: '',
            sticky: false,
        },
        transactionsFailedToLoad: {
            errorCode: {},
            title: '',
            description: 'TransactionsFailedToLoad',
            subDescription: '',
            sticky: false,
        },
        userSessionIsExpired: {
            errorCode: {
                'authentication-required': {
                    title: 'authentication-required',
                    description: 'AuthenticationRequired',
                    subDescription: '',
                },
            },
            title: '',
            description: 'UserSessionIsExpired',
            subDescription: '',
            sticky: false,
        },
        offerPurchaseFailed: {
            errorCode: {
                'invalid-request': {
                    title: 'invalid-request',
                    description: 'InvalidRequest',
                    subDescription: '',
                },
            },
            title: '',
            description: 'OfferPurchaseFailed',
            subDescription: '',
            sticky: false,
        },
        UpdatePaymentMethodSuccess: {
            errorCode: {},
            title: '',
            description: 'UpdatePaymentMethodSuccess',
            subDescription: '',
            sticky: false,
        },
        purchaseCouponVerificationFailed: {
            errorCode: {
                'invalid-request': {
                    title: '',
                    description: 'InvalidRequestForPurchaseCoupon',
                    subDescription: '',
                },
                PURCHASE_FAILED: {
                    title: '',
                    description: 'PurchaseFailed',
                    subDescription: '',
                },
                REQUEST_TIMEOUT: {
                    title: '',
                    description: 'equestTimeout',
                    subDescription: '',
                },
            },
            title: '',
            description: 'PurchaseCouponVerificationFailed',
            subDescription: '',
            sticky: false,
        },
        userConsentFailed: {
            errorCode: {
                UNKNOWN_API_ERROR: {
                    title: '',
                    description: 'UnknownApiErrorForUserConsent',
                    subDescription: '',
                },
                401: {
                    title: '',
                    description: '401ErrorForUserConsent',
                    subDescription: '',
                },
            },
            title: '',
            description: 'UserConsentFailed',
            subDescription: '',
            sticky: false,
        },
        requestTimeout: {
            errorCode: {
                UNSUBSCRIBE_PRODUCT_FAILED: {
                    title: '',
                    description: 'equestTimeout',
                    subDescription: '',
                },
            },
            title: '',
            description: 'equestTimeout',
            subDescription: '',
            sticky: false,
        },
        notFound: {
            errorCode: {
                UNSUBSCRIBE_PRODUCT_FAILED: {
                    title: '',
                    description: 'UnsubscribeProductFailed',
                    subDescription: '',
                },
            },
            title: '',
            description: 'NotFound',
            subDescription: '',
            sticky: false,
        },
        getSelectedPaymentMethodFailed: {
            errorCode: {},
            title: '',
            description: 'SelectedPaymentMethodFailed',
            subDescription: '',
            sticky: false,
        },
        searchUserFailed: {
            title: '',
            description: 'SearchUserFailed',
            subDescription: '',
            sticky: false,
        },
        careAgentLogutSuccess: {
            errorCode: {},
            title: '',
            description: 'CareAgentLogoutSuccess',
            subDescription: '',
            sticky: false,
        },
        clearRequestDetailsPolling: {
            errorCode: {},
            title: '',
            description: 'ClearRequestDetailsPolling',
            subDescription: '',
            sticky: false,
        },
        genericAuthCodeError: {
            errorCode: {},
            title: '',
            description: 'GenericAuthCodeError',
            subDescription: '',
            sticky: false,
        },
        subscribeUnactive: {
            errorCode: {},
            title: '',
            description: 'SubscriptionUnactive',
            subDescription: '',
            sticky: false,
        },
        consecutiveSubscriptionUpdate: {
            errorCode: {},
            title: '',
            description: 'ConsecutiveSubscriptionUpdate',
            subDescription: '',
            sticky: false,
        },
    },
    // Dynamic Content FIREBASE_COMMON_CONFIG
    analytics: {
        // This configuration allowyou to disable Analytics
        disabled: true,
        // This value force to get user consent beforce enabling Analytics.
        // Not required for all country (Example: Required in US/Europe, not really in Asia)
        // UI to be implemented for getting user consent
        userConsentRequired: false,
        // For device sampling, percentage of devices allowed for analytics
        percentageEnabled: 100,
        // Force User Personnal data collection even if user did not provide his consent
        forcePersonalUserDataCollection: true,
        // Session of analytics. By default 30 minutes before closing a session
        // sessionDurationMs: 30 * 60 * 1000,
        // Define the list of supported event (Native and Custom)
        supportedEvents: [
            // -- Events enabled by default --
            'screen',
            'login',
            'sign_up',
            'app_open',
            'starter_kit',
            'select_content',
            'purchase',
            'search',
            // --- Custom Events ---
            'bootstrap_data',
            'logout',
            'unsubscribe',
            'activate_subscription',
            'playback_start',
            'playback_pause',
            'playback_resume',
            'playback_stop',
            'playback_error',
            'emi',
            // Additionnal errors from the UI
            'app_error',
            'favorites_add_content',
            'favorites_remove_content',
            'add_reminder',
            'remove_reminder',
            'setting_update_parental_control',
            'setting_update_pin',
            'setting_reset_pin',
            'setting_update_password',
        ],
    },
    profileSubMenuOptions: [
        {
            key: 'profile',
            value: 'Profile',
        },
        {
            key: 'transaction-activity',
            value: 'Transaction Activity',
        },
        {
            key: 'faq',
            value: 'FAQ',
        },
        {
            key: 'logout',
            value: 'Log Out',
        },
    ],
    footerOptions: {
        background_color: 'rgba(255, 255, 255, 1)',
        languageList: ['English', 'Spanish', 'French', 'Japanese'],
        menuItemList: [
            {
                menuItem: 'Your Privacy Choices',
                link: 'https://about.att.com/privacy/choices-and-controls.html',
            },
            {
                menuItem: 'Health Privacy Notice',
                link: 'https://about.att.com/privacy/StateLawApproach/washington-health-privacy-notice.html',
            },
            {
                menuItem: 'Privacy Policy',
                link: 'https://about.att.com/privacy.html',
            },
            {
                menuItem: 'Terms of Use',
                link: 'https://www.att.com/legal/terms.attWebsiteTermsOfUse.html',
            },
            {
                menuItem: 'Legal',
                link: 'https://www.att.com/legal/legal-policy-center.html',
            },
            {
                menuItem: 'Contact',
                link: 'https://www.att.com/support/contact-us/',
            },
            {
                menuItem: 'Help',
                link: 'https://www.att.com/support/',
            },
        ],
        socialHandlerList: [
            {
                platform: 'Facebook',
                handler: 'https://www.facebook.com/ATT',
            },
            {
                platform: 'Messengers',
                handler: 'https://www.google.co.in/',
            },
            {
                platform: 'Instagram',
                handler: 'https://www.instagram.com/att/',
            },
            {
                platform: 'Youtube',
                handler: 'https://www.youtube.com/att',
            },
            {
                platform: 'Twitter',
                handler: 'https://x.com/att',
            },
        ],
    },
    countries: {
        US: 'US',
    },
    partnerSubscriptionType: 'ENTITLEMENT',
    eligibilityEnabled: true,
    paymentMethodView: {
        viewType: 'modal', // 'basic', 'modal' | default is 'basic'
        hosted: true, // false for internal integration, true when hosting an iframe
        eventOrigin: 'att.com',
    },
    cartRemoveIconClass: 'remove-icon',
    defaultCurrency: 'USD',
    showContactDetails: true,
    isTermsAndConditionDynamic: true,
    isProductGroupsSwimlane: true,
    impersonatePollingInterval: 10000,
    impersonatePollingExpiration: 60000,
    logoUrl: 'http://att.com',
    otpExpiryTime: 300,
    updateSubscriptionTimeDelay: 15000,
}

export default config
