import config from './production.js'

const conf = {
    ...config,
    carePortal: {
        urls: {
            adminAuthUrl: 'https://attustest02-ulm-pdt.m1amdocs.io/',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: '',
        OAuthParam: {
            scope: 'openid profile',
            extras: {},
        },
    },
    urls: {
        ...config.urls,
        apiUrl: 'https://attustest02-ulm-pdt.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://attustest02-ulm-pdt.m1amdocs.io',
        userPreferencesUrl: 'https://attustest02-ulm-pdt.m1amdocs.io/customData',
        storefrontUrl: 'https://attustest02-ulm-pdt.m1amdocs.io/storefront',
        emi: {
            baseUrl: 'https://waf-attus-metadata-api-prebk002.ifs.vubiquity.com/emi',
            homePath: '3.0/{population}/1',
            defaultPopulation: 'pdt',
        },
        proxyUrl: 'https://waf-attus-metadata-api-prebk002.ifs.vubiquity.com/maculosa/v3.1',
        ifsApiUrl: 'https://plm-prodtest.m1amdocs.io/el/pdt2',
        iframePaymentUrl: 'https://test3.att.com/acctmgmt/paymentmgmt/paymentmethods',
    },
    env: 'preprod2',
    firebaseConfig: {
        enabled: false,
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: '',
    },
    clientId: 'm16374',

    productGroups: {
        Amazon: {
            image: '',
            title: '',
            Description: '',
            thumbnail: '',
            backdrop: '',
            products: ['IFSAmazonBasic', 'IFSAmazonStandard', 'IFSAmazonPremium'],
            isPauseSupported: false,
            moreInfoLink: '',
        },

        Netflix: {
            image: '',
            title: '',
            Description: '',
            thumbnail: '',
            backdrop: '',
            products: ['IFSNetflixBasic', 'IFSNetflixStandard', 'IFSNetflixPremium'],
            isPauseSupported: false,
            moreInfoLink: '',
        },
        Hulu: {
            image: '',
            title: 'HULU',
            description: 'Endless entertainment with HULU',
            thumbnail:
                'https://waf-attus-metadata-api-prebk001.ifs.vubiquity.com/emi/3.0/pdt/1/discovery/resources/669775924fcce.jpeg',
            backdrop: 'https://waf-attus-metadata-api-prebk001.ifs.vubiquity.com/emi/6672773144523.jpeg',
            products: [
                'HULU-HIGH',
                'HULU-HIGH-YEARLY',
                'HULU-LOW',
                'HULU-LOW-MONTHLY-DEFAULT',
                'HULU-LOW-YEARLY',
                'HULU-MEDIUM',
                'HULU-MEDIUM-YEARLY',
                'HULU-SPECIAL-TEST',
            ],
            isPauseSupported: true,
            moreInfoLink: '',
        },
        Experian: {
            image: '',
            title: 'Experian',
            description: 'Endless entertainment with Experian',
            thumbnail: 'https://waf-attus-metadata-api-prebk001.ifs.vubiquity.com/emi/66976eecd3fb9.jpeg',
            backdrop: 'https://waf-attus-metadata-api-prebk001.ifs.vubiquity.com/emi/6672773144523.jpeg',
            products: [
                'EXPERIAN-HIGH-MONTHLY',
                'EXPERIAN-HIGH-YEARLY',
                'EXPERIAN-LOW-MONTHLY',
                'EXPERIAN-LOW-MONTHLY-DEFAULT',
                'EXPERIAN-LOW-YEARLY',
                'EXPERIAN-MEDIUM-MONTHLY',
                'EXPERIAN-MEDIUM-MONTHLY-ADHOC',
                'EXPERIAN-MEDIUM-YEARLY',
            ],
            isPauseSupported: false,
            moreInfoLink: '',
        },
        Nickacademy: {
            image: '',
            title: 'Nick Academy',
            description: 'A unique app from Nickelodeon that teaches empowering skills and cool content through play!',
            thumbnail:
                'https://waf-attus-metadata-api-prebk001.ifs.vubiquity.com/emi/3.0/pdt/1/discovery/resources/66ba1b2140768.png',
            backdrop: 'https://waf-attus-metadata-api-prebk001.ifs.vubiquity.com/emi/66b240c10a9e7.png',
            products: ['NICK-ACADEMY'],
            isPauseSupported: false,
            moreInfoLink: 'https://nick.academy',
        },
    },
}

export default conf
