import {createEffect, createSignal, onMount, Show} from 'solid-js'
import {AuthorizationContext} from './AuthorizationContext'
import {AuthorizationHandler, AuthorizationType, AuthorizerOptions} from './authorizationHandler'
import {LoadingScreen, useConfig} from '../components'
import {profileSelector, useDispatch, useSelector} from '../store'
import {fetchProfile, getShoppingCart} from 'store-actions'

interface AuthorizationProviderProps {
    voltApi: any
    children: any
}
export const AuthorizationProvider = (props: AuthorizationProviderProps) => {
    const [authorizationHandler, setAuthorizationHandler] = createSignal<AuthorizationHandler>()

    const {getConfig} = useConfig()
    const dispatch = useDispatch()
    const profile = useSelector(profileSelector)

    const authConfig: AuthorizerOptions = {
        allowGuest: getConfig().allowGuest,
        clientId: getConfig().clientId,
        clientSecret: getConfig().clientSecret,
        redirectUri: location.origin + '/oauthredirect',
        authorizationType: AuthorizationType.REDIRECT,
        responseType: AuthorizationType.TOKEN,
        grant_type: getConfig().grant_type,
        scope: getConfig().OAuthParam.scope,
        authorityConfiguration: {
            authorization_endpoint: getConfig().urls.authUrl + getConfig().OAuthParam.endpoint.auth,
            token_endpoint: getConfig().OAuthParam.endpoint.fullTokenUrl
                ? getConfig().OAuthParam.endpoint.fullTokenUrl
                : getConfig().urls.authUrl + getConfig().OAuthParam.endpoint.token,
            revocation_endpoint: '',
            registration_endpoint: '',
        },
        extras: getConfig().OAuthParam.extras,
    }

    const isAuthPending = useSelector((state: {auth: {isPending: any}}) => state.auth.isPending)
    const [waitForProfile, setWaitForProfile] = createSignal(false)

    onMount(async () => {
        const _authzHandler = new AuthorizationHandler(authConfig)
        setAuthorizationHandler(_authzHandler)
        if (window.location.pathname !== '/oauthredirect') {
            setWaitForProfile(true)
            dispatch(
                fetchProfile({
                    onSuccess: () => {
                        setWaitForProfile(false)
                    },
                    onError: error => {
                        console.log(error)
                        setWaitForProfile(false)
                    },
                })
            )
        }
    })

    createEffect(() => {
        if (profile()) {
            dispatch(getShoppingCart())
        }
    })

    return (
        <AuthorizationContext.Provider value={{authorizationHandler: () => authorizationHandler() as any}}>
            <Show when={isAuthPending() || waitForProfile()} fallback={props.children}>
                <LoadingScreen />
            </Show>
        </AuthorizationContext.Provider>
    )
}

export interface AuthorizationProviderBehavior {
    authorizationHandler: () => AuthorizationHandler
}
