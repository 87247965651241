import {VisibilityEnum} from '../emuns/visibilityEnum'

const buildNodeHierarchy = (nodesMap: Record<string, any>, node: any, ignoreList: string[] = []) => {
    const tempArray: any = []

    if (node?.children) {
        for (const child of node?.children) {
            const childNode = nodesMap[child]

            if (childNode && isValidNode(childNode)) {
                const {children, ...rest} = childNode

                tempArray.push({
                    ...rest,
                    children: buildNodeHierarchy(nodesMap, childNode),
                })
            }
        }
        return tempArray
    }

    return []
}

export const getMenuOptions = (nodesMap: Record<string, any>, modulesConfig: {[key: string]: any}, profile) => {
    const _menuOptions: any = []
    const nodeKeyArray = Object.keys(nodesMap)
    for (const nodeKey of nodeKeyArray) {
        const node = nodesMap[nodeKey]
        if (node.parent === 'root' && isValidNode(node)) {
            if (node?.children) {
                _menuOptions.push({
                    ...node,
                    children: buildNodeHierarchy(nodesMap, node),
                })
            } else {
                _menuOptions.push({...node})
            }
        }
    }

    const res = _menuOptions.map((node: any) => {
        const roodId = node.id.split('-')[1]
        if (!roodId) return node
        const moduleNode = modulesConfig[roodId]
        return {
            ...node,
            ...(moduleNode ?? {}),
        }
    })

    return res
        .filter(r => {
            // We filter menu options based on visibility
            switch (r.visibility) {
                case VisibilityEnum.All:
                    return true
                case VisibilityEnum.Authenticated:
                    return !!profile
                case VisibilityEnum.Unauthenticated:
                    return !profile
                default:
                    return true
            }
        })
        .sort((a, b) => (a.position > b.position ? 1 : -1))
}

const isValidNode = (node: any) => {
    return node.isContentNode() || node.isPortalNode() || node.isNavigationNode()
}
